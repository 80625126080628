import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const initialState = {
    snackbar: {
        showSnackbar: false,
        color: '#13C791',
        message: "state"
    }
}


export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
    getters
};