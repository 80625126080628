import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

let user = JSON.parse(sessionStorage.getItem("ajoData"));

const initialState = {

    agent: user ? user : {
        first_name: "",
        last_name: ""
    },
    transactions: [],
    balance: 0
}

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
    getters
};