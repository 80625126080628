let timeout

export default {
    SHOW_SNACKBAR: (state, payload) => {

        state.snackbar.showSnackbar = true;
        state.snackbar.message = payload.message;
        if (payload.success) {
            state.snackbar.color = "#13C791"
        } else {
            state.snackbar.color = "#C03737"
        }

        timeout = setTimeout(() => {
            state.snackbar.showSnackbar = false
            state.snackbar.message = "";
            console.log("snackbar timeout")
        }, payload.timeout || 5000)

    },
    CLOSE_SNACKBAR: (state) => {
        state.snackbar.showSnackbar = false;
        state.snackbar.message = "";
        clearTimeout(timeout)
    }
};
