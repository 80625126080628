import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";



const initialState = {
    dataPlans: {
        mtn: [],
        airtel: [],
        glo: [],
        etisalat: []
    },
    TVSubscriptions: {
        dstv: [],
        gotv: [],
        startimes: [],
        linda: []
    }

}

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
    getters
};