import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";



const initialState = {
    savers: [],
    saverProfile: {},
    transactions: [],
    saverDetails: {},
    piggys: []

}

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
    getters
};