import axios from 'axios'

let BASE_URL = "https://staging-ajopoint.herokuapp.com"

function authHeader() {
    let user = JSON.parse(sessionStorage.getItem('ajoToken'));

    if (user && user.access) {
        return { Authorization: `Bearer ${user.access}` };
    } else {
        return {};
    }
}
export const getUsers = async (data) => await axios.get(BASE_URL + '/api/user/get_savers/', {
    headers: authHeader()
})

export const registerUser = async (data) => await axios.post(BASE_URL + '/api/accounts/register/', data, {
    headers: authHeader()
})

export const sendToken = async (data) => await axios.post(BASE_URL + '/api/user/initiate_user_verification/', data)

export const verifyToken = async (data) => await axios.put(BASE_URL + `/api/user/verify_user/`, data, {
    headers: authHeader()
})

export const addSaver = async (data) => await axios.post(BASE_URL + '/api/user/agent_create_user/', data, {
    headers: authHeader()
})

export const getBalance = async (data) => await axios.get(BASE_URL + `/transaction/transaction/get_balance/?phone=${data}`, {
    headers: authHeader()
})

export const getAgentTransactions = async (data) => await axios.get(BASE_URL + `/transaction/transaction/get_all_agent_transaction/?phone=${data}`, {
    headers: authHeader()
})

export const getUserTransactions = async (data) => await axios.get(BASE_URL + `/transaction/transaction/get_all_user_transaction/?phone=${data}`, {
    headers: authHeader()
})

export const initializeTransaction = async (data) => await axios.post(BASE_URL + `/transaction/top_up/Initialize_transaction/`, data, {
    headers: authHeader()
})

export const validateTransaction = async (data) => await axios.post(BASE_URL + '/transaction/top_up/validate_transaction/', data, {
    headers: authHeader()
})
export const getUserDetails = async (data) => await axios.get(BASE_URL + `/api/user/${data}/`, {
    headers: authHeader()
})

export const getAllUserPiggy = async (data) => await axios.get(BASE_URL + `/transaction/transaction/get_all_piggy/?phone=${data}`, {
    headers: authHeader()
})

export const createPiggy = async (data) => await axios.post(BASE_URL + `/transaction/savings/`, data, {
    headers: authHeader()
})

export const getBankList = async () => await axios.get(BASE_URL + `/transaction/withdraw/list_bank/`, {
    headers: authHeader()
})

export const createTransferRecipient = async (data) => await axios.post(BASE_URL + `/transaction/withdraw/create_transfer_recipient/`, data, {
    headers: authHeader()
})

export const initiateWithdraw = async (data) => await axios.post(BASE_URL + `/transaction/withdraw/initiate_a_transfer/`, data, {
    headers: authHeader()
})

export const topUpPiggy = async (data) => await axios.post(BASE_URL + `/transaction/agent/top_up_any_user_account/`, data, {
    headers: authHeader()
})

export const withdrawPiggy = async (data) => await axios.post(BASE_URL + `/transaction/agent/withdraw_from_any_user_account/`, data, {
    headers: authHeader()
})

export const uploadKYC = async (data) => await axios.post(BASE_URL + `/api/user/kyc_customer_information/`, data, {
    headers: authHeader(),
    'Content-Type': 'multipart/form-data'
})

export const updateKYC = async (data) => await axios.put(BASE_URL + `/api/user/update_kyc_customer_information/`, data, {
    headers: authHeader(),
    'Content-Type': 'multipart/form-data'
})





