export default {
    updateAgents: (state, data) => {
        if (Array.isArray(data)) {
            state.agents = data
        } else {
            state.agents = [data, ...state.agents]
        }
        console.log(state.agents)
    },
    updateAgentDetails: (state, data) => {
        state.agent = data
    },
    updateStats: (state, data) => {
        state.stats = data
    }
}