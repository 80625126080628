import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";



const initialState = {
    agents: [],
    agent: {},
    stats: {
        no_of_agents: 0,
        all_savers: 0,
        commission: 0
    }

}

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
    getters
};