import { getAgentTransactions } from "../../services/api-services"


export default {

    getBalance({ commit }) {

    },
    getAgentTransactions({ commit }, data) {
        return getAgentTransactions(data).then(
            res => {
                commit("updateTransactions", res.data)

                return Promise.resolve(res.data)
            },
            err => {
                console.log(err)
                commit("updateTransactionsFail")
            }
        )
    },
}