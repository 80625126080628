export default [
    {
        path: "/admin",
        name: "admin",
        component: () => import(/* webpackChunkName: "dashboard" */ "../components/dashboard/Dashboard.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/savers",
        name: "savers",
        component: () => import(/* webpackChunkName: "dashboard" */ "../components/savers/Savers.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/agents",
        name: "adminAgents",
        component: () => import(/* webpackChunkName: "dashboard" */ "../components/agents/Agents.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/agent",
        name: "AgentDetails",
        component: () => import(/* webpackChunkName: "dashboard" */ "../components/agents/AgentDetails.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/admin/saver/:id",
        name: "saverDetails",
        component: () => import(/* webpackChunkName: "dashboard" */ "../components/savers/SaverDetails.vue"),
        meta: {
            requiresAuth: true
        }
    },


];
