export default [
    {
        path: "/agent",
        name: "dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ "../components/dashboard/Dashboard.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/agent/users",
        name: "savers",
        component: () => import(/* webpackChunkName: "dashboard" */ "../components/savers/Savers.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/agent/users/:userId",
        name: "customerProfile",
        component: () => import(/* webpackChunkName: "dashboard" */ "../components/savers/SaverDetails.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/agent/wallet",
        name: "wallet",
        component: () => import(/* webpackChunkName: "dashboard" */ "../components/wallet/Wallet.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/agent/payments",
        name: "wallet",
        component: () => import(/* webpackChunkName: "dashboard" */ "../components/payments/Payments.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/agent/profile",
        name: "profile",
        component: () => import(/* webpackChunkName: "dashboard" */ "../components/profile/Profile.vue"),
        meta: {
            requiresAuth: true
        }
    },

];
