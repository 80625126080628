<template>
  <component v-bind:is="app"> </component>
</template>

<script>
import Onboarding from "./onboarding/App";

export default {
  name: "App",

  components: {
    Onboarding,
  },

  data() {
    return {
      app: null,
    };
  },

  watch: {
    $route(to, from) {
      const pathString = (route) => {
        let stringEnd = route.path.indexOf("/", 1);
        if (stringEnd > 0) {
          return route.path.substring(1, stringEnd);
        } else {
          return route.path.substring(1);
        }
      };
      let user = JSON.parse(sessionStorage.getItem("ajoToken"));
      if (pathString(to) === "agent") {
        this.app = () =>
          import(/* webpackChunkName: "Agent" */ "./agent/App.vue");
      } else if (pathString(to) === "admin") {
        this.app = () =>
          import(/* webpackChunkName: "Admin" */ "./admin/App.vue");
      } else {
        this.app = () =>
          import(/* webpackChunkName: "Home" */ "./onboarding/App.vue");
      }
    },
  },
};
</script>
<style>
* {
  font-family: "Poppins", sans-serif;
}
.row {
  margin: unset !important;
}
h1,
h2,
h3,
h4,
h5 {
  color: #272253 !important;
}

.v-btn {
  text-transform: capitalize !important;
  font-size: 12px !important;
  height: 40px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none !important;
}
.v-dialog {
  box-shadow: unset !important;
  margin: 0 0 !important;
}
</style>
