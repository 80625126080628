<template>
  <v-app id="app" :style="cssVars" class="onboarding">
    <v-main>
      <v-container fluid class="app-container">
        <v-expand-transition>
          <v-card
            class="snackbar pa-5"
            :color="snackbar.color"
            v-show="snackbar.showSnackbar"
            width="100vw"
          >
            <h5 class="text-center">{{ snackbar.message }}</h5>
            <v-btn icon color="black" @click="closeSnackBar" class="close_btn">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card>
        </v-expand-transition>
        <v-theme-provider root>
          <router-view></router-view>
        </v-theme-provider>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations("snackbar", ["CLOSE_SNACKBAR"]),
    closeSnackBar() {
      this.CLOSE_SNACKBAR();
    },
  },
  computed: {
    ...mapGetters("snackbar", ["snackbar"]),
    //logic

    //css
    cssVars() {
      if (!this.$vuetify.theme.dark) {
        return {
          "--bg-color": this.$vuetify.theme.themes.light.primary,
        };
      } else {
        return {
          "--bg-color": this.$vuetify.theme.themes.dark.primary,
        };
      }
    },
  },
};
</script>

<style scoped>
.onboarding >>> .container {
  padding: unset !important;
  height: 100vh !important;
}
.snackbar {
  height: 8vh;
  z-index: 1;
  position: absolute;
}
.snackbar h5 {
  color: white !important;
}
.close_btn {
  position: absolute;
  right: 2rem;
  top: 10px;
}
</style>