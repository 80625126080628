export default {
    addMoney: (state, data) => {

    },
    updateUserDetails: (state, data) => {
        state.agent = data
    },
    updateTransactions: (state, data) => {
        if (Array.isArray(data)) {
            state.transactions = [...data]

        } else {
            state.transactions = [data, ...state.transactions]
        }
    },
    updateTransactionsFail: (state) => {
        state.transactions = [...state.transactions]
    },
    updateBalance: (state, data) => {
        state.balance = data
    },
}