import Vue from 'vue'
import VueRouter from 'vue-router'
import onboarding from "../onboarding/router"
import agent from "../agent/router"
import admin from "../admin/router"

Vue.use(VueRouter)

const routes = [
  ...onboarding,
  ...agent,
  ...admin
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let user = JSON.parse(sessionStorage.getItem('ajoData'))
  let newDate = new Date().getTime();
  let expDate = user ? user.exp : null;
  let parsedDate = parseInt(newDate.toString().substring(0, 10));
  const tokenValid = () => {
    if (expDate > parsedDate) {

      return true
    } else {
      sessionStorage.removeItem("ajoData")
      sessionStorage.removeItem("ajoToken")
      return false
    }
  }

  const pathString = (route) => {
    let stringEnd = route.path.indexOf('/', 1)
    if (stringEnd > 0) {
      console.log(route.path.substring(1, stringEnd))
      return route.path.substring(1, stringEnd)
    } else {
      console.log(route.path.substring(1))
      return route.path.substring(1)
    }
  }

  let protectedRoute = ["admin", "agent"]
  let adminRoute = ["admin"]
  let agentRoute = ["agent"]

  if (adminRoute.includes(pathString(to)) && user) {
    if (tokenValid() && user.token_type === "access" && user.role === "SuperAdmin") {
      next()
    } else {
      next("/login")
    }
  }
  else if (agentRoute.includes(pathString(to)) && user) {
    if (tokenValid() && user.token_type === "access" && user.role === "Agent") {
      next()
    } else {
      next("/login")
    }
  }
  else if ((tokenValid() && user.token_type === "access") && !protectedRoute.includes(pathString(to))) {
    if (user.role === "SuperAdmin") {
      next("/admin")
    } else {
      next("/agent")
    }
  } else if (protectedRoute.includes(pathString(to)) && !user) {
    next("/login")
  } else {
    next()
  }

})

export default router
