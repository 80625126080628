import axios from 'axios'

let BASE_URL = "https://staging-ajopoint.herokuapp.com"

function authHeader() {
    let user = JSON.parse(sessionStorage.getItem('ajoToken'));

    if (user && user.access) {
        return { Authorization: `Bearer ${user.access}` };
    } else {
        return {};
    }
}
export const getAgents = async (data) => await axios.get(BASE_URL + '/transaction/admin/get_all_agent_accounts/', {
    headers: authHeader()
})

export const getAllSavers = async (data) => await axios.get(BASE_URL + '/transaction/admin/get_all_user_accounts/', {
    headers: authHeader()
})

export const getSaverDetails = async (data) => await axios.get(BASE_URL + '/transaction/admin/get_all_user_accounts/', {
    headers: authHeader()
})

export const getAgentTransactions = async (data) => await axios.get(BASE_URL + `/transaction/transaction/get_all_agent_transaction/?phone=${data}`, {
    headers: authHeader()
})

export const getAllTransactions = async (data) => await axios.get(BASE_URL + '//', {
    headers: authHeader()
})
export const getStats = async (data) => await axios.get(BASE_URL + '/transaction/admin/get_agent_stats/', {
    headers: authHeader()
})
export const suspend = async (data) => await axios.patch(BASE_URL + `/transaction/admin/deactivate_user_accounts/?phone=${data}`, {}, {
    headers: authHeader()
})
export const activate = async (data) => await axios.patch(BASE_URL + `/transaction/admin/activate_user_accounts/?phone=${data}`, {}, {
    headers: authHeader()
})

export const getKYC = async (data) => await axios.get(BASE_URL + `/transaction/admin/get_user_documents/?phone=${data}`, {
    headers: authHeader()
})

export const getAgentSavers = async (data) => await axios.get(BASE_URL + `/transaction/admin/get_agent_users/?phone=${data}`, {
    headers: authHeader()
})

