export default {
    addDataPlans: (state, data) => {
        state.dataPlans[data.provider] = data.plans
    },
    addDataPlansFail: (state, data) => {
        state.dataPlans[data.provider] = [...data.plans]
    },
    addTVSubscriptionPlans: (state, data) => {
        state.TVSubscriptions[data.provider] = data.plans
    },
    addTVSubscriptionPlansFail: (state, data) => {
        state.TVSubscriptions[data.provider] = [...data.plans]
    },
}