export default [
    {
        path: "/",
        name: "home",
        redirect: { name: 'login' },
        component: () => import(/* webpackChunkName: "home" */ "../components/Home.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../components/Login.vue"),
    },
    {
        path: "/register",
        name: "register",
        component: () => import(/* webpackChunkName: "register" */ "../components/Register.vue"),
    },
    {
        path: "/verify",
        name: "verify",
        component: () => import(/* webpackChunkName: "register" */ "../components/verify/Verify.vue"),
    },
];
