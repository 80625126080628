export default {
    getBalance: state => state.balance,
    agent: state => state.agent,
    agentTransactions: state => state.transactions,
    agentTransactionsTransformed: state => state.transactions.map(transaction => {
        if (transaction.transaction_type === "Top-up") {
            return {
                type: "IN",
                details: transaction.title,
                time: transaction.created,
                amount: transaction.transaction_amount,
                transaction: "credit",
                transactionType: transaction.transaction_type,
                id: transaction.transaction_id,
                piggy_id: transaction.piggy_id
            }
        } else {
            return {
                type: "OUT",
                details: transaction.title,
                time: transaction.created,
                amount: transaction.transaction_amount,
                transaction: "debit",
                transactionType: transaction.transaction_type,
                id: transaction.transaction_id,
                piggy_id: transaction.piggy_id
            }
        }

    })
}