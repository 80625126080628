import { getDataPlans, getTVSubscriptionPlans } from "../../services/api-services"

export default {
    dataPlans({ commit }, data) {
        return getDataPlans(data).then(
            res => {
                let dataPlan = {
                    provider: data,
                    plans: res.data
                }
                commit("addDataPlans", dataPlan)
                return Promise.resolve(res.data)
            },
            err => {
                commit("addDataPlansFail")
                return Promise.reject(err)
            }
        )

    },
    TVSubscriptionPlans({ commit }, data) {
        return getTVSubscriptionPlans(data).then(
            res => {
                let dataPlan = {
                    provider: data,
                    plans: res.data
                }
                commit("addTVSubscriptionPlans", dataPlan)
                return Promise.resolve(res.data)
            },
            err => {
                commit("addTVSubscriptionPlansFail")
                return Promise.reject(err)
            }
        )

    }
}