import axios from 'axios'

let BASE_URL = "https://staging-ajopoint.herokuapp.com"

function authHeader() {
    let user = JSON.parse(sessionStorage.getItem('ajoToken'));

    if (user && user.access) {
        return { Authorization: `Bearer ${user.access}` };
    } else {
        return {};
    }
}
export const getDataPlans = (provider) => axios.get(BASE_URL + `/transaction/utility/get_airtime_information/?serviceID=${provider + "-data"}`, {
    headers: authHeader()
})
export const buyAirtimeData = (data) => axios.post(BASE_URL + `/transaction/utility/purchase_airtime/`, data, {
    headers: authHeader()
})
export const buyElectricity = (data) => axios.post(BASE_URL + `/transaction/utility/purchase_Electricity/`, data, {
    headers: authHeader()
})
export const getTVSubscriptionPlans = (provider) => axios.get(BASE_URL + `/transaction/utility/get_airtime_information/?serviceID=${provider}`, {
    headers: authHeader()
})
export const cableTransaction = async (data) => await axios.post(BASE_URL + `/transaction/utility/purchase_tv_subscription/`, data, {
    headers: authHeader()
})