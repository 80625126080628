export default {
    addSaver: (state, data) => {
        if (Array.isArray(data)) {
            state.savers = data
        } else {
            const { first_name, last_name, phone } = data
            let saver = {
                detail: {
                    name: `${first_name} ${last_name}`,
                    phone,
                    balance: 0
                }
            }
            state.savers = [saver, ...state.savers]
        };
    },
    addSaverFail: (state) => {
        state.savers = [...state.savers]
    },
    saveUserProfile: (state, data) => {
        Object.keys(data).forEach(element => {
            state.saverProfile[element] = data[element]
        });
        console.log(state.saverProfile)
    },
    updateTransactions: (state, data) => {
        if (Array.isArray(data)) {
            state.transactions = [...data]
        } else {
            state.transactions = [data, ...state.transactions]
        }
    },
    updateTransactionsFail: (state) => {
        state.transactions = [...state.transactions]
    },
    updateSaverDetails: (state, data) => {
        state.saverDetails = data
    },
    addPiggy: (state, data) => {
        if (Array.isArray(data)) {
            state.piggys = data
        } else {
            state.piggys = [data, ...state.piggys]
        }

    }
}