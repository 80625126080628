import { getSaverDetails } from "../../services/api-services"


export default {

    getCustomerTransactions({ commit }, data) {
        return getUserTransactions(data).then(
            res => {
                commit("updateTransactions", res.data)
                return Promise.resolve(res.data)
            },
            err => {
                console.log(err)
                commit("updateTransactionsFail")
            }
        )
    },
}