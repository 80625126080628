import Vue from 'vue'
import Vuex from 'vuex'
import customers from "@/agent/store/customers"
import agent from "@/agent/store/agent"
import admin from "@/admin/store/agents"
import savers from "@/admin/store/savers"
import payments from "./payments"
import snackbar from "./snackbar"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    customers,
    agent,
    payments,
    snackbar,
    admin,
    savers
  }
})
