import { getUsers, getUserTransactions } from "../../services/api-services"


export default {

    getAllCustomers({ commit }) {
        return getUsers().then(
            res => {
                commit("addSaver", res.data)
                console.log(res.data, "customers")
                return Promise.resolve(res.data)
            },
            err => {
                commit("addSaverFail")
                console.log(err)
                return Promise.reject(err)
            }
        )

    },
    getCustomerTransactions({ commit }, data) {
        return getUserTransactions(data).then(
            res => {
                commit("updateTransactions", res.data)
                return Promise.resolve(res.data)
            },
            err => {
                console.log(err)
                commit("updateTransactionsFail")
            }
        )
    },
}