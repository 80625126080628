import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';



Vue.config.productionTip = false

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend('phonenumber', {
  validate(value, { prefix }) {
    return value.charAt(0) === prefix && !isNaN(value);
  },
  params: ['prefix'],
  message: "Phonenumber entered is not valid"
});

extend('passwordConfirmation', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Passwords do not match'
});

localize("en", en);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
